import AddBlog from './pages/Blog/Add';
import Blog from './pages/Blog/Blog';
import EditBlog from './pages/Blog/Edit';
import AddCase from './pages/CaseLaw/Add';
import CaseLaw from './pages/CaseLaw/CaseLaw';
import EditCase from './pages/CaseLaw/Edit';
import DashboardApp from './pages/DashboardApp';
import Statutes from './pages/Statutes/Statutes';
import AddStatutes from './pages/Statutes/Add';
import AddMember from './pages/Team/Add';
import EditMember from './pages/Team/Edit';
import Team from './pages/Team/Team';
import EditStatute from './pages/Statutes/Edit';
import Dictionary from './pages/Dictionary/Dictionary';
import AddDictionary from './pages/Dictionary/AddDictionary';
import Notifications from './pages/Notifications/Notifications';
import AddNotification from './pages/Notifications/Add';
import NotificationType from './pages/NotificationType/NotificationType';
import AddNotificationType from './pages/NotificationType/Add';
import Ordinance from './pages/Ordinance2/Ordinance2';
import AddOrdinance from './pages/Ordinance2/Add';
import OrdinanceDetail from './pages/OrdinanceDetails/OrdinanceDetail';
import AddOrdinanceDetails from './pages/OrdinanceDetails/Add';
import ServiceTypes from './pages/ServiceType/ServiceTypes';
import AddServiceType from './pages/ServiceType/Add';
import Insights from './pages/Insights/Insights';
import AddInsight from './pages/Insights/Add';
import Services from './pages/Services/Services';
import AddServices from './pages/Services/Add';
import ServiceDetail from './pages/ServiceDetail/ServiceDetail';
import AddServiceDetail from './pages/ServiceDetail/Add';
import AppointmentSlots from './pages/AppointmentSlots/AppointmentSlots';
import AddAppointmentSlots from './pages/AppointmentSlots/Add';
import Appointments from './pages/Appointments/Appointments';
import AppointmentAssign from './pages/AppointmentAssign/AppointmentAssign';
import Backgrounds from './pages/Backgrounds/Backgrounds';
import AddBackground from './pages/Backgrounds/Add';
import HeroSection from './pages/HeroSection/HeroSection';
import AddHeroSection from './pages/HeroSection/Add';
import AboutUs from './pages/Aboutus/Aboutus';
import AddAboutUs from './pages/Aboutus/Add';
import Clients from './pages/Clients/Clients';
import AddClient from './pages/Clients/Add';
import AdminRoles from './pages/AdminRole/AdminRoles';
import AddAdmin from './pages/AdminRole/AddAdmin';
import { Info, Service } from './pages/Queue';
import NtnRecord from './pages/NtnRecord';
import NtnUserFiles from './pages/NtnRecord/NtnUserFiles';
import Oversea from './pages/Queue/Oversea';
import User from './pages/User';
import CaseRequest from './pages/Queue/CaseRequest';

export const protectedRoutes = [
  {
    user: 'admin',
    routes: [
      { path: 'dashboard', element: <DashboardApp /> },
      { path: 'ntn-record', element: <NtnRecord /> },
      { path: 'ntn-record/:id/files', element: <NtnUserFiles /> },
      { path: 'blog', element: <Blog /> },
      { path: 'blog/addBlog', element: <AddBlog /> },
      { path: 'blog/editBlog', element: <EditBlog /> },
      { path: 'team', element: <Team /> },
      { path: 'team/addMember', element: <AddMember /> },
      { path: 'team/editMember', element: <EditMember /> },
      { path: 'caselaws', element: <CaseLaw /> },
      { path: 'caselaws/addCase', element: <AddCase /> },
      { path: 'caselaws/editCase', element: <EditCase /> },
      { path: 'statutes', element: <Statutes /> },
      { path: 'addStatute', element: <AddStatutes /> },
      { path: 'editStatute', element: <EditStatute /> },
      { path: 'dictionary', element: <Dictionary /> },
      { path: 'addDictionary', element: <AddDictionary /> },
      { path: 'notifications', element: <Notifications /> },
      { path: 'notifications/addNotification', element: <AddNotification /> },
      { path: 'notificationsType', element: <NotificationType /> },
      { path: 'addNotificationType', element: <AddNotificationType /> },
      { path: 'ordinance/addOrdinance', element: <AddOrdinance /> },
      { path: 'ordinance', element: <Ordinance /> },
      { path: 'ordinanceDetail', element: <OrdinanceDetail /> },
      { path: 'ordinanceDetail/addOrdinanceDetails', element: <AddOrdinanceDetails /> },
      { path: 'serviceTypes', element: <ServiceTypes /> },
      { path: 'serviceTypes/addServiceType', element: <AddServiceType /> },
      { path: 'insights', element: <Insights /> },
      { path: 'insights/addInsight', element: <AddInsight /> },
      { path: 'services', element: <Services /> },
      { path: 'services/addService', element: <AddServices /> },
      { path: 'serviceDetails', element: <ServiceDetail /> },
      { path: 'serviceDetails/:superCategory/:type', element: <ServiceDetail /> },
      { path: 'serviceTypes/addServiceDetail', element: <AddServiceDetail /> },
      { path: 'serviceTypes/addServiceDetail/:superCategory/:type', element: <AddServiceDetail /> },
      { path: 'appointmentSlots', element: <AppointmentSlots /> },
      { path: 'appointmentSlots/addSlot', element: <AddAppointmentSlots /> },
      { path: 'appointments', element: <Appointments /> },
      { path: 'appointmentsController/assign', element: <AppointmentAssign /> },
      { path: 'background', element: <Backgrounds /> },
      { path: 'background/addBackground', element: <AddBackground /> },
      { path: 'heroSection', element: <HeroSection /> },
      { path: 'heroSection/addHeroSection', element: <AddHeroSection /> },
      { path: 'aboutus', element: <AboutUs /> },
      { path: 'aboutus/addAboutus', element: <AddAboutUs /> },
      { path: 'clients', element: <Clients /> },
      { path: 'clients/addClient', element: <AddClient /> },
      { path: 'admins', element: <AdminRoles /> },
      { path: 'admins/addAdmin', element: <AddAdmin /> },
      { path: '/query/info', element: <Info /> },
      { path: '/query/services', element: <Service /> },
      { path: '/query/oversea', element: <Oversea /> },
      { path: '/query/case-request', element: <CaseRequest /> },
      { path: '/users', element: <User /> },
    ],
  },
  {
    user: 'front_desk_admin',
    routes: [
      { path: 'dashboard', element: <AppointmentAssign /> },
      { path: 'blog', element: <Blog /> },
      { path: 'blog/addBlog', element: <AddBlog /> },
      { path: 'blog/editBlog', element: <EditBlog /> },
      { path: 'team', element: <Team /> },
      { path: 'team/addMember', element: <AddMember /> },
      { path: 'team/editMember', element: <EditMember /> },
      { path: 'caselaws', element: <CaseLaw /> },
      { path: 'caselaws/addCase', element: <AddCase /> },
      { path: 'caselaws/editCase', element: <EditCase /> },
      { path: 'statutes', element: <Statutes /> },
      { path: 'addStatute', element: <AddStatutes /> },
      { path: 'editStatute', element: <EditStatute /> },
      { path: 'dictionary', element: <Dictionary /> },
      { path: 'addDictionary', element: <AddDictionary /> },
      { path: 'notifications', element: <Notifications /> },
      { path: 'notifications/addNotification', element: <AddNotification /> },
      { path: 'notificationsType', element: <NotificationType /> },
      { path: 'addNotificationType', element: <AddNotificationType /> },
      { path: 'ordinance/addOrdinance', element: <AddOrdinance /> },
      { path: 'ordinance', element: <Ordinance /> },
      { path: 'ordinanceDetail', element: <OrdinanceDetail /> },
      { path: 'ordinanceDetail/addOrdinanceDetails', element: <AddOrdinanceDetails /> },
      { path: 'serviceTypes', element: <ServiceTypes /> },
      { path: 'serviceTypes/addServiceType', element: <AddServiceType /> },
      { path: 'insights', element: <Insights /> },
      { path: 'insights/addInsight', element: <AddInsight /> },
      { path: 'services', element: <Services /> },
      { path: 'services/addService', element: <AddServices /> },
      { path: 'serviceDetails', element: <ServiceDetail /> },
      { path: 'serviceDetails/:superCategory/:type', element: <ServiceDetail /> },
      { path: 'serviceTypes/addServiceDetail', element: <AddServiceDetail /> },
      { path: 'serviceTypes/addServiceDetail/:superCategory/:type', element: <AddServiceDetail /> },
      { path: 'appointmentSlots', element: <AppointmentSlots /> },
      { path: 'appointmentSlots/addSlot', element: <AddAppointmentSlots /> },
      { path: 'appointments', element: <Appointments /> },
      { path: 'appointmentsController/assign', element: <AppointmentAssign /> },
      { path: 'background', element: <Backgrounds /> },
      { path: 'background/addBackground', element: <AddBackground /> },
      { path: 'heroSection', element: <HeroSection /> },
      { path: 'heroSection/addHeroSection', element: <AddHeroSection /> },
      { path: 'aboutus', element: <AboutUs /> },
      { path: 'aboutus/addAboutus', element: <AddAboutUs /> },
      { path: 'clients', element: <Clients /> },
      { path: 'clients/addClient', element: <AddClient /> },
      { path: 'admins', element: <AdminRoles /> },
      { path: 'admins/addAdmin', element: <AddAdmin /> },
    ],
  },
];
